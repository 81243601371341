const footer = document.querySelector("footer");
const stickyButtons = document.querySelector("[data-sticky-buttons]");
import { screens } from "tailwindcss/defaultTheme";
const isScreenSize = (key) => {
  return window.matchMedia(`(min-width: ${screens[key]})`).matches;
};
const adjustCtasButtons = () => {
  if (!stickyButtons) {
    return;
  }
  if (isScreenSize("md")) {
    footer.style.marginBottom = "0px";
    return;
  }
  const marginBottom = stickyButtons.offsetHeight;
  footer.style.marginBottom = `${marginBottom}px`;
};
if (stickyButtons) {
  document.addEventListener("scroll", () => {
    adjustCtasButtons();
  });
  document.addEventListener("on:toggle", () => {
    adjustCtasButtons();
  });
  window.addEventListener("resize", () => {
    adjustCtasButtons();
  });
}
